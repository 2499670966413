.btn {

  &-2xl {
    font-size: $h5-font-size;
    padding: $spacer * 0.85 $spacer * 3.15; // 17px 63px
  }

  &-dark.btn-underline {
    background: transparent;
    border-radius: 0;
    border-width: 0 0 rem(2px) 0;
    color: $dark-color;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: $spacer-2;
  }

  &-primary-light {
    background-color: $primary-light-color;
    border: $primary-light-color;

    &:hover {
      background-color: lighten($primary-light-color, 1%);
      border: lighten($primary-light-color, 1%);
    }

    &:active,
    &:focus {
      background-color: darken($primary-light-color, 2%);
      border: darken($primary-light-color, 2%);
    }
  }

  &-white {
    background-color: $white;
    border: $border-width solid $inputs-border-color;
    color: $dark-color;

    &.show,
    &:hover,
    &:active,
    &:first-child:active,
    :not(.btn-check) + &:active {
      background-color: $white;
      border: $border-width solid $inputs-border-color;
      color: $dark-color;

      *[class^="fa"] {
        color: $body-secondary-color;
      }
    }

    *[class^="fa"] {
      color: $body-secondary-color;
    }
  }
}
