// Colours
$white: #fff;
$black: #000;

$aquamarine: #36f1cc; // Private - Primary
$mustard: #ffd72a;    // Private - Secondary
$spring: #15cc2b;     // Private - Success
$azure: #003a6f;      // Private - Info
$sundawn: #f38f00;    // Private - Warning
$tomato: #f22630;     // Private - Danger
$seasalt: #f8fafc;    // Private - Light
$rich-black: #020617; // Private - Dark

$mauve: #be81ff;     // Private - Highlight
$amaranth: #f1365b;  // Private - Emphasize

$cyan: #e1fdf7;          // Private - Primary light
$black-squeeze: #f8fcfb; // Private - Primary lighter
$turquoise: #38b79c;     // Private - Primary dark
$cosmic-latte: #fffbeb;  // Private - Secondary light
$honeydew: #dcf7df;      // Private - Success light
$alice-blue: #f0f9ff;    // Private - Info light
$linen: #fff7ed;         // Private - Warning light
$lavender: #fbdbdd;      // Private - Danger light

$magnolia: #f9f4ff;  // Private - Highlight light
$wisp-pink: #fef5f7; // Private - Emphasize light

$catskill: #f1f5f9;   // Private - Border color
$mint-tulip: #ccf5ec; // Private - Border color primary
$white-ice: #d9f8f2;  // Private - Box shadow color

$fiord: #475569;  // Private - Body color
$regent: #7e8f9e; // Private - Body color secondary

$primary-color: $aquamarine;
$secondary-color: $mustard;
$success-color: $spring;
$info-color: $azure;
$warning-color: $sundawn;
$danger-color: $tomato;
$light-color: $seasalt;
$dark-color: $rich-black;

$highlight-color: $mauve;
$emphasize-color: $amaranth;

$primary-light-color: $cyan;
$primary-dark-color: $turquoise;
$secondary-light-color: $cosmic-latte;
$success-light-color: $honeydew;
$info-light-color: $alice-blue;
$warning-light-color: $linen;
$danger-light-color: $lavender;

$highlight-light-color: $magnolia;
$emphasize-light-color: $wisp-pink;

// Spacing
$spacer: rem(20px);
$spacer-1: $spacer * 0.2;   // 4px
$spacer-2: $spacer * 0.4;   // 8px
$spacer-3: $spacer * 0.6;   // 12px
$spacer-4: $spacer * 0.8;   // 16px
$spacer-5: $spacer;         // 20px
$spacer-6: $spacer * 1.2;   // 24px
$spacer-7: $spacer * 1.6;   // 32px
$spacer-8: $spacer * 1.8;   // 36px - maybe can be dropped?
$spacer-9: $spacer * 2;     // 40px
$spacer-10: $spacer * 2.4;  // 48px
$spacer-11: $spacer * 3.2;  // 64px – maybe too big a jump?
$spacer-12: $spacer * 4;    // 80px – maybe not needed
$spacer-13: $spacer * 4.8;  // 96px
$spacer-14: $spacer * 5.75; // 115px

// Body
$body-color: $fiord;

$body-secondary-color: $regent;

// Components
$border-width: rem(1px);
$border-color: $catskill;
$border-color-primary: $mint-tulip;

$base-border-radius: rem(12px);
$md-border-radius: $base-border-radius; // 12px
$sm-border-radius: $base-border-radius * 0.6666666667; // 8px
$lg-border-radius: $base-border-radius * 1.3333333333; // 16px

$md-box-shadow: rem(0 5px 5px 0) $white-ice;
$sm-box-shadow: rem(0 3px 3px 0) $white-ice;
$lg-box-shadow: rem(0 8px 8px 0) $white-ice;

// Typography
$font-family: 'Poppins', 'Helvetica Neue', Helvetica, Arial, sans-serif;  // Private
$headings-family: 'Gilroy', 'Helvetica Neue', Helvetica, Arial, sans-serif;  // Private
$font-family-sans-serif: $font-family;

$base-font-size: rem(16px);
$xs-font-size: $base-font-size * 0.875; // 14px
$sm-font-size: $base-font-size;         // 16px
$md-font-size: $base-font-size * 1.125; // 18px
$lg-font-size: $base-font-size * 1.25;  // 20px
$xl-font-size: $base-font-size * 1.5;   // 24px

$h1-font-size: $base-font-size * 4.6875; // 75px
$h2-font-size: $base-font-size * 3.75;   // 60px
$h3-font-size: $base-font-size * 3;      // 48px
$h4-font-size: $base-font-size * 2.25;   // 36px
$h5-font-size: $base-font-size * 1.875;  // 30px
$h6-font-size: $base-font-size * 1.5;    // 24px

$lead-font-size: $lg-font-size;

$fw-normal: 500;
$fw-semibold: 600;
$fw-bold: 700;
$fw-extrabold: 800;
$fw-black: 900;

// Buttons + Forms
$inputs-border-color: darken($border-color, 5%);
