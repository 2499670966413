.navbar {

  &-brand img {
    height: rem(42px);

    @include media-breakpoint-up(md) {
      height: rem(46px);
    }
  }

  &-nav {

    @include media-breakpoint-up(xl) {
      margin-left: $spacer-7;
    }

    @include media-breakpoint-up(xxxl) {
      margin-left: $spacer-8;
    }

    .nav-item {
      padding-bottom: $spacer-5;

      @include media-breakpoint-up(xl) {
        padding: 0;
      }
    }

    .nav-item + .nav-item {
      border-top: $border-width solid $border-color;
      padding-top: $spacer-5;

      @include media-breakpoint-up(xl) {
        padding: 0;
        border-top: 0;
        margin-left: $spacer-8;
      }

      @include media-breakpoint-up(xxxl) {
        margin-left: $spacer-9;
      }
    }

    .nav-link {

      @include media-breakpoint-up(xl) {
        padding-bottom: 0;
        padding-top: 0;
        position: relative;
      }

      &::before {

        @include media-breakpoint-up(xl) {
          background-color: $dark-color;
          bottom: -$spacer-3;
          height: rem(1px);
          left: 50%;
          position: absolute;
          transform: translateX(-50%);
          width: 75%;
        }
      }

      &.active {
        font-weight: $fw-semibold;

        &::before {

          @include media-breakpoint-up(xl) {
            content: "";
          }
        }
      }
    }
  }

  &-toggler {
    border-width: 0;
  }

  .dropdown {

    button:focus {
      box-shadow: none;
    }

    &-item {
      color: $dark-color;
      font-size: $sm-font-size;
    }
  }
}
