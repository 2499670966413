// Color system
$white: $white;
$black: $black;

$primary: $primary-color;
$secondary: $secondary-color;
$success: $success-color;
$info: $info-color;
$warning: $warning-color;
$danger: $danger-color;
$light: $light-color;
$dark: $dark-color;

// Spacing
$spacers: (
  0: 0,
  1: $spacer-1,
  2: $spacer-2,
  3: $spacer-3,
  4: $spacer-4,
  5: $spacer-5,
  6: $spacer-6,
  7: $spacer-7,
  8: $spacer-8,
  9: $spacer-9,
  10: $spacer-10,
  11: $spacer-11,
  12: $spacer-12,
  13: $spacer-13,
  14: $spacer-14
);

$enable-negative-margins: true;

// Body
$body-color: $body-color;
$body-bg: $white;

$body-secondary-color: $body-secondary-color;

// Links
$link-decoration: none;

// Paragraphs
$paragraph-margin-bottom: $spacer-3;

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
);

// Grid containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  xxxl: 1640px,
);

// Grid columns
$grid-columns: 24;
$grid-gutter-width: $spacer-5;

// Components
$border-width: $border-width;

$border-color: $border-color;

$border-radius: $md-border-radius;
$border-radius-sm: $sm-border-radius;
$border-radius-lg: $lg-border-radius;

$box-shadow: $md-box-shadow;
$box-shadow-sm: $sm-box-shadow;
$box-shadow-lg: $lg-box-shadow;

// Typography
$font-family-sans-serif: $font-family;

$font-size-base: $md-font-size;
$font-size-sm: $sm-font-size;
$font-size-lg: $lg-font-size;

$font-weight-normal: $fw-normal;
$font-weight-bold: $fw-bold;

$h1-font-size: $h1-font-size;
$h2-font-size: $h2-font-size;
$h3-font-size: $h3-font-size;
$h4-font-size: $h4-font-size;
$h5-font-size: $h5-font-size;
$h6-font-size: $h6-font-size;

$headings-margin-bottom: $spacer-2;
$headings-font-family: $headings-family;
$headings-font-weight: $fw-extrabold;
$headings-color: $dark-color;

$lead-font-size: $lg-font-size;
$lead-font-weight: $fw-normal;

$text-muted: $body-secondary-color;

$hr-margin-y: 0;
$hr-color: $border-color;

$hr-opacity: 1;

// Buttons + Forms
$input-btn-padding-y: $spacer-3;
$input-btn-padding-x: $spacer-6;
$input-btn-font-size: $sm-font-size;

$input-btn-padding-y-lg: $spacer-4;
$input-btn-padding-x-lg: $spacer-9;

// Buttons
$btn-color: $dark-color;

$btn-font-weight: $fw-semibold;

$btn-border-radius: 50rem;
$btn-border-radius-sm: 50rem;
$btn-border-radius-lg: 50rem;

// Navs
$nav-link-padding-y: 0;
$nav-link-padding-x: 0;
$nav-link-font-size: $sm-font-size;
$nav-link-color: $dark-color;

// Navbar
$navbar-padding-y: $spacer-5;

$navbar-nav-link-padding-x: 0;

$navbar-brand-font-size: 0;
$nav-link-height: auto;
$navbar-brand-height: auto;
$navbar-brand-padding-y: 0;
$navbar-brand-margin-end: 0;

$navbar-toggler-padding-y: $spacer-1;
$navbar-toggler-padding-x: $spacer-1;
$navbar-toggler-font-size: $h4-font-size;

$navbar-light-color: $dark-color;
$navbar-light-hover-color: $dark-color;
$navbar-light-active-color: $dark-color;
$navbar-light-disabled-color: $body-secondary-color;
$navbar-light-icon-color: $dark-color;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-icon-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");

// Dropdowns
$dropdown-min-width: rem(240px);
$dropdown-padding-y: $spacer-2;
$dropdown-spacer: $spacer-2;
$dropdown-color: $body-color;
$dropdown-bg: $white;
$dropdown-border-color: $border-color;
$dropdown-border-radius: $lg-border-radius;
$dropdown-divider-margin-y: $spacer * 0.3; // 6px

$dropdown-link-color: $body-color;
$dropdown-link-hover-color: $dark-color;
$dropdown-link-hover-bg: $light-color;

$dropdown-link-active-color: $dropdown-link-hover-color;
$dropdown-link-active-bg: $dropdown-link-hover-bg;

$dropdown-link-disabled-color: $body-secondary-color;

$dropdown-item-padding-y: $spacer * 0.3; // 6px
$dropdown-item-padding-x: $spacer;       // 20px

$dropdown-header-color: $body-secondary-color;

// Cards
$card-spacer-y: $spacer-6;
$card-spacer-x: $spacer-6;

// Offcanvas
$offcanvas-border-width: 0;
$offcanvas-box-shadow: none;
$offcanvas-horizontal-width: rem(300px);
$offcanvas-transition-duration: 0.15s;
