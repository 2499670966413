.text-primary-dark {
  color: $primary-dark-color !important;
}

.text-emphasize {
  color: $emphasize-color !important;
}

.ff-body {
  font-family: $font-family !important;
}

.fw-semibold {
  font-weight: $fw-semibold !important;
}

.fw-black {
  font-weight: $fw-black !important;
}

.small-xs {
  font-size: $xs-font-size !important;
}

.lead-xl {
  font-size: $xl-font-size !important;
}
